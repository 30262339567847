// Here you can add other styles
.letter-avatar {
  display:inline-block;
  font-size:1em;
  width:2.5em;
  height:2.5em;
  line-height:2.5em;
  text-align:center;
  border-radius:50%;
  background: #eb7634;
  vertical-align:middle;
  margin-left:0.2em;
  margin-right:0.2em;
  color:white;
}

.centered-table td {
    vertical-align: middle;
}

.centered-table tbody th {
    vertical-align: middle;
}

table td.numeric-col {
    text-align: right;
}

table th.numeric-col {
    text-align: right;
}

.product-table .product-basket-col {
    width: 215px;
    min-width: 215px;
    padding-right: 17px;
}

.basket-table .basket-quantity-col {
    width: 155px;
    min-width: 155px;
}

.product-table .product-basket-row {
    background-color: rgba(0, 100, 255, 0.04);
}

.product-table .product-basket-row:hover {
    background-color: rgba(0, 100, 255, 0.07);
}

.total-price {
    font-weight: 700;
}

.react-autosuggest__container {
    width: 100%;
}

.react-autosuggest__input--open {
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
}

.react-autosuggest__suggestions-container {
    display: none;
}

.react-autosuggest__suggestions-container--open {
    display: block;
    position: absolute;
    top: 35px;
    width: 100%;
    border: 1px solid #aaa;
    background-color: #fff;
    font-family: Helvetica, sans-serif;
    font-weight: 300;
    font-size: 14px;
    border-bottom-left-radius: 4px;
    border-bottom-right-radius: 4px;
    z-index: 2;
}

.react-autosuggest__suggestions-list {
    margin: 0;
    padding: 0;
    list-style-type: none;
    max-height: 200px;
    overflow-y: auto;
}

.react-autosuggest__suggestion {
    cursor: pointer;
    padding: 10px 20px;
}

.react-autosuggest__suggestion--highlighted {
    background-color: #ddd;
}

.pointable {
    cursor: pointer;
}

.text-button, .text-button:active, .text-button:focus {
    outline: none;
    padding: 0;
    border: none;
    background-color: transparent;
}

.sort-wrapper span::before {
    font-family: FontAwesome;
}

.sort-wrapper {
}

.sort-wrapper span.icon-sortable::before {
    content: "\f0dc";
}

.sort-wrapper span.icon-sortable-up::before {
    content: "\f0de";
}

.sort-wrapper span.icon-sortable-down::before {
    content: "\f0dd";
}

.rows-per-page-select {
    width: 200px;
}

.invoice-detail-input {
    max-width: 100px;
}

.invoice-detail-input-sm {
    max-width: 60px;
}

.invoice-detail-quantity-col {
    min-width: 80px;
}

.invoice-detail-unit-price-col {
    min-width: 120px;
}

.invoice-detail-total-price-col {
    min-width: 120px;
}

.invoice-detail-brutto-col {
    min-width: 80px;
}

.invoice-detail-netto-col {
    min-width: 80px;
}

.invoice-detail-country-code-col {
    min-width: 80px;
}

.invoice-detail-customs-code-col {
    min-width: 115px;
}

.invoice-detail-description-col {
    min-width: 100px;
}


.invoice-control-column {
    min-width: 125px;
}

.invoice-delete-column {
    min-width: 108px;
}

.project-price-delete-column {
    min-width: 108px;
}

.order-control-column {
    min-width: 108px;
}

.pure-checkbox {
    position: inherit;
    margin-top: 0px;
    margin-left: 0px;
}

.warning-tooltip .tooltip-inner {
    background-color: #ffc107;
    color: #000;
    
}

.warning-tooltip .arrow::before {
    border-left-color: #ffc107 !important;
}

.warning-border {
    border-color: #ffc107;
}

.basket-info {
    font-size: 1.6em;
    display:inline-block;
    text-align:center;
    border-radius:5%;
    color: #20a8d8 !important;
    background-color: #fff;
    border: 1px solid #20a8d8;
    vertical-align:middle;
    padding-left:5px;
    padding-right:5px;
    color:white;
}

.last-sync-warning {
    color: #f86c6b !important;
    font-weight: bold;
}

.checkbox-no-right-margin {
    vertical-align: bottom !important;
    margin-right: 0px !important;
}

.checkbox-no-right-margin input {
    vertical-align: bottom !important;
    margin-right: 0px !important;
    margin-bottom: 5px !important;
}

.out-invoice-action-column {
    min-width: 430px;
}